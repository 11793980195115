<template>
  <!-- 角色管理 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button
      style="margin-bottom: 20px"
      v-checkbtn="isShowBtn(AUTH_BTN.role_list_add)"
      type="primary"
      size="small"
      icon="el-icon-plus"
      @click="$router.push({ name: 'RoleAdd' })"
      >新增</el-button
    >
    <tp-table :isNeedSerialNumber="false" :tableData="roleList" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />

    <!-- 授权 -->
    <el-drawer ref="drawer" title="角色权限" :visible.sync="drawer" :show-close="false">
      <el-card>
        <div slot="header">
          <el-button type="primary" size="small" @click="saveAuthority">保存设置</el-button>
        </div>
        <div>
          <el-tree
            ref="tree"
            highlight-current
            :data="menuTree"
            show-checkbox
            node-key="id"
            default-expand-all
            :default-checked-keys="grantMenuObj.menu_ids"
            :props="defaultProps"
            @check="checkChange"
          >
          </el-tree>
        </div>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>
import { getRoleList, removeRole, getMenusTree, grantMenu, getRoleMenu, getRoleDetail, editRole } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: 'ID',
    prop: 'id',
    customRender(h, row) {
      return <p class="one-line">{row['id']}</p>
    }
  },
  {
    label: '角色',
    prop: 'role_name',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['role_name']}>
          {row['role_name']}
        </p>
      )
    }
  },
  {
    label: '描述',
    prop: 'remark',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['remark']}>
          {row['remark']}
        </p>
      )
    }
  },
  {
    label: '创建时间',
    prop: 'created_at',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['created_at']}>
          {row['created_at']}
        </p>
      )
    }
  },
  {
    label: '操作',
    width: '300',
    customRender(h, row) {
      return (
        <div>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.role_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'RoleEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link v-show={this.isShowBtn(this.AUTH_BTN.role_list_menu)} underline={false} style="margin:0 10px" type="primary" onClick={() => this.showMenu(row['id'])}>
            授权菜单
          </el-link>
          <el-link v-show={this.isShowBtn(this.AUTH_BTN.role_list_del)} underline={false} type="primary" onClick={() => this.goRemove(row['id'])}>
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      menuModule: 'admin',
      drawer: false,
      roleList: [],
      columns,
      total: 0,
      currentPage: 1,
      pageSize: 20,
      defaultProps: {
        children: 'children',
        label: 'menu_name',
        value: 'id'
      },
      menuTree: [],
      // 授权信息
      grantMenuObj: {
        id: null,
        menu_ids: []
      },
      searchData: {
        roleName: ''
      },
      allKeys: [],
      detailInfo: {}
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getRoleList()
    }
  },
  mounted() {
    this.getRoleList()
    // this.getModuleList()
    this.apiQueryMenuTree()
  },

  methods: {
    async getRoleList() {
      let params = { roleName: this.searchData.roleName, page: this.currentPage, pageSize: this.pageSize }
      let res = await getRoleList(params)
      this.roleList = res?.data || []
      this.total = res?.total || 0
    },

    async apiQueryMenuTree() {
      this.menuTree = await getMenusTree()
    },
    // 复选框被点击时的回调
    checkChange() {
      // 获取当前被选中的菜单id数组
      this.grantMenuObj.menu_ids = this.$refs.tree.getCheckedKeys()
      this.allKeys = this.$refs.tree.getCheckedNodes(false, true).map((item) => item.id)
    },
    // 保存菜单授权
    async saveAuthority() {
      // let data = { id: this.grantMenuObj.id, menus: this.allKeys }
      this.detailInfo.menus = this.allKeys
      await editRole(this.detailInfo).then(() => {
        this.$message.success('授权成功')
        this.drawer = false
        this.getRoleList()
      })
    },
    goAdd() {
      this.$router.push({ name: 'RoleDetail' })
    },
    goEdit(id) {
      this.$router.push({ name: 'RoleDetail', params: { id: id || undefined } })
    },
    async goRemove(id) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          removeRole({ id }).then(() => {
            this.$message.success('删除成功')
            this.getRoleList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async showMenu(id) {
      this.grantMenuObj.menu_ids = []
       this.apiQueryMenuTree()
      this.drawer = true
      this.grantMenuObj.id = id
      // const { menus } = await getRoleDetail({ id })
      this.detailInfo = await getRoleDetail({ id })
      // this.grantMenuObj.menu_ids = this.fileterMenuIds(this.detailInfo.menus).filter(item => !this.$refs.tree.getNode(item).childNodes.length)
      const { menus } = this.detailInfo
      this.$nextTick(() => {
        if (menus) {
          this.grantMenuObj.menu_ids = menus.filter((item) => this.$refs.tree.getNode(item).childNodes.length == 0)
        }
      })
    },
    fileterMenuIds(list) {
      let menuIds = []
      list.forEach((item) => {
        if (item.children && item.children.length > 0) {
          menuIds = this.fileterMenuIds(item.children)
        } else {
          // if (item.hasPermit == 1) {
          //   menuIds.push(item.id)
          // }
          menuIds.push(item.id)
        }
      })
      return menuIds
    },
    // async getModuleList() {
    //   this.MENU_MODULE = await getModuleList()
    // },
    handleClick() {
      this.getRoleList()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getRoleList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .arrow {
      text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
    .el-form-item {
      width: 20%;
      min-width: 300px;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
  .el-divider {
    margin: 20px 0;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
